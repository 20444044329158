import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import { _linkResolver } from "../core/utils"
import FormMailto from "./ui/FormMailto"
import FormMail from "./ui/FormMail"

const query = graphql`
  query {
    prismicHeader {
      data {
        site_name {
          text
        }
        address {
          richText
        }
        opening_hours {
          richText
          text
        }
        naviguation {
          item {
            uid
            type
            document {
              ... on PrismicArtist {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Aside = () => {
  const {
    prismicHeader: {
      data: { site_name, address, opening_hours, naviguation },
    },
  } = useStaticQuery(query)
  // console.log(naviguation)
  const nav = naviguation.filter(el => el.item.uid !== null)
  // console.log(nav)

  return (
    <aside className="relative mb-md">
      <div className="md:sticky top-header-height- md:top-md ">
        <div className="site-name">{site_name.text}</div>
        {address.richText && (
          <div className="texte mb-sm">
            <PrismicRichText field={address.richText} />
          </div>
        )}
        {opening_hours.text && (
          <div className="texte mb-sm">
            <PrismicRichText field={opening_hours.richText} />
          </div>
        )}
        <div className="mb-md">
          <FormMail />
        </div>
        <nav role="navigation" aria-label="Menu">
          <ul>
            {nav.map(({ item }) => (
              <li key={item.uid}>
                <Link to={_linkResolver(item)}>
                  {item.document.data.title.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* <FormMailto /> */}
      </div>
    </aside>
  )
}

export default Aside
