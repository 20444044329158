exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index--.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-js": () => import("./../../../src/pages/logo.js" /* webpackChunkName: "component---src-pages-logo-js" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-templates-artist-jsx": () => import("./../../../src/templates/Artist.jsx" /* webpackChunkName: "component---src-templates-artist-jsx" */),
  "component---src-templates-exhibition-jsx": () => import("./../../../src/templates/Exhibition.jsx" /* webpackChunkName: "component---src-templates-exhibition-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */)
}

