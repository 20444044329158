import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { useRef } from "react"

const HoverImage = ({ parent, image, alt }) => {
  const [show, setShow] = useState(false)
  const [transform, setTransform] = useState({ x: 0, y: 0 })
  const { width, height } = image.gatsbyImageData
  const ref = useRef()
  // console.log(width, height)

  useEffect(() => {
    // console.log(ref.current.parentNode.parentNode)
    const container = ref.current.parentNode.parentNode
    // const container = document.querySelector(`.${parent}`)
    // console.log(container)

    container.removeEventListener("mouseover", _onOver)
    container.removeEventListener("mouseout", _onOut)
    container.addEventListener("mousemove", _update)

    setTimeout(() => {
      container.addEventListener("mouseover", _onOver)
      container.addEventListener("mouseout", _onOut)
    }, 800)

    return () => {
      container.removeEventListener("mouseover", _onOver)
      container.removeEventListener("mouseout", _onOut)
      container.addEventListener("mousemove", _update)
    }
  }, [])

  const _onOver = e => {
    console.log("on over")
    setShow(true)
    const container = ref.current.parentNode.parentNode
    // document.querySelector(`.${parent}`).addEventListener("mousemove", _update)
    container.addEventListener("mousemove", _update)
  }
  const _onOut = e => {
    console.log("on out")

    setShow(false)
    const container = ref.current.parentNode.parentNode
    container.removeEventListener("mousemove", _update)
  }
  const _update = e => {
    // console.log(e)
    setTransform({
      x: e.offsetX - width / 2,
      y: e.offsetY - height / 2,
    })
  }
  // console.log(show)
  return (
    <div
      ref={ref}
      className={clsx(
        "hover-image absolute left-0 top-0 pointer-events-none -z-10 will-change-transform transition-opacity transition-all-  hidden-sm"
      )}
      style={{
        transform: `translate(${transform.x}px, ${transform.y}px)`,
        opacity: show ? 1 : 0,
      }}
    >
      <img src={image.url} width={width} height={height} alt={alt} />
    </div>
  )
}

export default HoverImage
